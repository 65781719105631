<template>
  <v-app style="background-color: #E1E6E8">
    <app-bar />
    <v-main>
      <router-view />
      <v-overlay :value="pause" absolute>
        <v-icon size="10vw">
          mdi-pause
        </v-icon>
      </v-overlay>
    </v-main>
    <ag-notify />
  </v-app>
</template>

<script>
import AgNotify from 'src/components/common/AgNotify'
import AppBar from 'src/components/layouts/AppBar'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AgNotify,
    AppBar,
  },
  computed: {
    ...mapGetters('session', {
      pause: 'pause',
    }),
  },
}
</script>
