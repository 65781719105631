<template>
  <v-app-bar
    dark
    color="primary"
    app
    flat
  >
    <template v-if="isHomePage">
      <ag-confirm-dialog
        :title="$t('general.confirmation')"
        :message="$t('login.message.logoutConfirm')"
        :cancel-label="$t('general.cancel')"
        :confirm-label="$t('general.disconnect')"
        validation-btn-color="error"
        @confirm="logout"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
      </ag-confirm-dialog>
    </template>
    <template v-else>
      <v-btn
        icon
        dark
        @click="$router.go(-1)"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </template>
    <v-spacer />

    <v-toolbar-title v-text="title" />

    <v-spacer />
    <v-btn
      icon
      dark
      @click="$router.go()"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-icon
      :color="connectionStatusIcon.color"
      class="ma-3"
      v-text="connectionStatusIcon.icon"
    />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'

export default {
  name: 'AppBar',
  components: {
    AgConfirmDialog,
  },
  computed: {
    ...mapGetters('socket', {
      isConnected: 'isConnected',
    }),
    ...mapGetters('me', {
      isConnectedToSession: 'isConnectedToSession',
    }),
    ...mapGetters('ui', {
      title: 'title',
    }),
    connectionStatusIcon () {
      if (this.isConnected && this.isConnectedToSession) {
        return { icon: 'mdi-wifi', color: 'success' }
      }
      return this.isConnected
        ? { icon: 'mdi-wifi', color: 'warning' }
        : { icon: 'mdi-wifi-off', color: 'error' }
    },
    isHomePage () {
      return this.$route.name === 'Home'
    },
  },
  methods: {
    ...mapActions('me', {
      logout: 'logout',
    }),
  },
}
</script>
